import React, { useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import { Row, Col, Button, Modal } from "react-bootstrap";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ContactForm from "../components/forms/contact";

const ContactModal = ({ show, handleClose }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Contact me regarding Cut-Out Lines</Modal.Title>
    </Modal.Header>
    <div className="px-5 py-5">
      <ContactForm referrer="Cut-Out Lines" />
    </div>
  </Modal>
);
export const CutOutLinesPageTemplate = ({ title, titleSEO, products }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <SEO title={titleSEO} />
      <ContactModal show={show} handleClose={() => setShow(false)} />
      <Row className="my-5 pt-3 mx-0 d-flex flex-row justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <h1 className="block-invert">{title}</h1>
        </Col>
      </Row>
      <Row className="d-flex flex-row justify-content-center mx-0">
        {products.map((product) => (
          <>
            <Col className="d-flex flex-column text-center justify-content-center justify-content-xxl-start align-items-center col-12 col-md-4 col-xxl-4 mb-5 mb-xxl-0">
              <Link to={product.url}>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: product.imageSection.image,
                    alt: product.title,
                    style: { width: "clamp(200px, 20vw, 400px" },
                  }}
                />
              </Link>
              <h2 className="mt-5 d-none d-xxl-block" style={{ color: `#${product.titleColor}` }}>
                {product.title}
              </h2>
              <p className="px-5 mx-5 d-none d-xxl-block">
                <ReactMarkdown source={product.description} escapeHtml={false} linkTarget="_blank" />
              </p>
              {/* <p className="px-5 mx-5 d-none d-xxl-block mt-auto">
                <Button variant="light" onClick={() => navigate(product.url)}>
                  Buy Now
                </Button>
              </p> */}
            </Col>
            {/* This Col replaces the text above for views <xxl */}
            <Col className="d-block d-xxl-none col-12 col-md-6 col-xxl-4 mb-5 mb-xxl-0 text-center text-md-left">
              <h2 className="mt-5" style={{ color: `#${product.titleColor}` }}>
                {product.title}
              </h2>
              <p>
                <ReactMarkdown source={product.description} escapeHtml={false} linkTarget="_blank" />
              </p>
              <p>
                <Button variant="light" onClick={() => navigate(product.url)}>
                  Buy Now
                </Button>
              </p>
            </Col>
          </>
        ))}
      </Row>
      <Row className="my-5 mx-0 d-flex flex-column justify-content-center">
        <Col className="d-flex flex-row justify-content-center align-items-center">
          <Button variant="dark" onClick={() => setShow(!show)}>
            Contact Me
          </Button>{" "}
          <span className="ml-3">
            or call: <a href="tel:2096020734">209-602-0734</a>
          </span>
        </Col>
      </Row>
    </>
  );
};

const CutOutLinesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <CutOutLinesPageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        products={frontmatter.products}
      />
    </Layout>
  );
};

export default CutOutLinesPage;

export const pageQuery = graphql`
  query CutOutLinesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "cut-out-lines-page" } }) {
      frontmatter {
        title
        titleSEO
        products {
          title
          titleColor
          url
          description
          imageSection {
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
